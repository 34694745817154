/* eslint-env jquery */

"use strict";

const initYoutubeApi = () => {
    let currentScriptTag = document.getElementById("youtube-iframe-api");

    if (!currentScriptTag) {
        var tag = document.createElement("script"),
            firstScriptTag = document.getElementsByTagName("script")[0],
            currentDateInt = parseInt(new Date().getTime(), 10);

        tag.id = "youtube-iframe-api";
        tag.src = "https://www.youtube.com/iframe_api?version=" + currentDateInt;
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
};

const startVideo = ($videoPlayer, videoId, loop, isMuted) => {
    let playerTag = $videoPlayer[0].querySelector(".youtube-video-player");

    try {
        new window.YT.Player(playerTag, {
            videoId: videoId,
            playerVars: {
                showinfo: 0,
                enablejsapi: 1,
                mute: isMuted,
                loop: loop,
                playlist: videoId,
            },
            events: {
                onStateChange: onPlayerStateChange,
                onReady: onPlayerReady,
            },
        });
        return true;
    } catch {
        return false;
    }
};

let currentVideoPlayer = null;

const onPlayerStateChange = (event) => {
    const playing = window.YT.PlayerState.PLAYING;
    if (event?.data === playing) {
        if (currentVideoPlayer && currentVideoPlayer !== event.target) {
            currentVideoPlayer?.stopVideo();
        }
        currentVideoPlayer = event.target;
    }
};

const onPlayerReady = (event) => {
    event?.target?.playVideo();

    if (currentVideoPlayer && currentVideoPlayer !== event.target) {
        currentVideoPlayer?.stopVideo();
    }
    currentVideoPlayer = event.target;
};

const initPlayer = ($videoPlayer) => {
    const videoId = $videoPlayer.data("video-id");
    const isMuted = $videoPlayer.data("video-muted");
    const isLoop = $videoPlayer.data("video-loop");


    if (!$videoPlayer.hasClass("player-initialized")) {
        initYoutubeApi();

        setTimeout(() => {
            const videoStarted = startVideo($videoPlayer, videoId, isLoop, isMuted);
            if (!videoStarted) {
                initPlayer($videoPlayer);
                return;
            }
            $videoPlayer.addClass("player-initialized");
        }, 1000);
    }
};

(function () {
    $(".youtube-video").each((index, player) => {
        const $videoPlayer = $(player);
        const isAutoplay = $videoPlayer.data("video-auto-play");

        if (isAutoplay) {
            initPlayer($videoPlayer);
        }
    });

    $(".youtube-video").on("click", function (ev) {
        const $videoPlayer = $(ev.target).parents(".youtube-video");
        initPlayer($videoPlayer);
    });
})();
